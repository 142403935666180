import React from 'react';
import styles from './styles.module.scss';
import ReviewCard from 'components/atoms/ReviewCard/ReviewCard';
import { courseReviewData } from 'constants/reviews.dummy';

const filterContent = courseReviewData.map(({ url, by, date, ...rest }) => rest);

export default function index() {
  return (
    <div className={styles.container}>
      <h2>Reviews</h2>
      <div className={styles.cardList}>
        {filterContent?.map((d, i) => (
          <ReviewCard {...d} key={i} />
        ))}
      </div>
    </div>
  );
}
