import React, { useEffect, useState, useRef } from 'react';
import styles from './BannerProfile.module.scss';
import { Button, ImageCropModal, ProfilePic, Loader } from 'components/ui';
import PropTypes from 'prop-types';
import { ReactComponent as Upload } from 'assets/icons/uploadd.svg';
import { deleteFromS3, getS3Url } from '../../../helpers/s3Handler';
import { handleAsync } from 'helpers';
import { useUpdateBannerImageMutation } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import useSelectCacheData from 'hooks/useSelectCacheData';
import useUploadBlob from 'hooks/useUploadBlob';
import { showErrorToast } from 'utils/toast';
import HiddenFileInput from '../HiddenFileInput/HiddenFileInput';
import { MPUBLISHER_BACKGROUNDS, RECOMMENDED_IMAGES } from 'constants/homeData';
import { useTour } from '@reactour/tour';
import { useSelector } from 'react-redux';
import { getCompanyThemeSelector } from 'store/login.slice';
import { useGetProfileActiveTemplate } from 'components/organisms/Profile/hooks/templates';
import { CvTemplates } from 'components/organisms/Profile/templates/utils';
import CreateModal from '../CreateModal/CreateModal';
import { ReactComponent as MPublisher } from 'assets/icons/mpublisher.svg';

export default function BannerProfile({
  image,
  profile,
  bannerShade,
  profileId,
  width,
  height,
  extraClass,
  ...rest
}) {
  const sharedData = useSelectCacheData('getSharedProfileBySlug', {});
  const [selectedImage, setSelectedImage] = useState(null);
  const [editBanner, setEditBanner] = useState(false);
  const [updateBannerImage, { isLoading }] = useUpdateBannerImageMutation();
  const { uploadBlob, isUploading } = useUploadBlob();
  const [imageFile, setImageFile] = useState(null);
  const [openImageCroppedModal, setOpenImageCroppedModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const uploadModalRef = useRef(null);
  const { currentStep, setCurrentStep } = useTour();
  const { templateType } = useGetProfileActiveTemplate();
  const [mpublisherBackgrounds, setMpublisherBackgrounds] = useState(false);
  const [tempImage, setTempImage] = useState(null);

  const getBannerImage = () => {
    if (isSharedMode()) {
      if (sharedData?.data) {
        const bnrImg = sharedData.data.sharedProfile?.bannerImage;
        return bnrImg ? getS3Url(bnrImg) : CvTemplates[templateType]?.backgroundImage;
      }
    }
    if (image) {
      return getS3Url(image);
    }
    return CvTemplates[templateType]?.backgroundImage;
  };
  const [bannerImage, setBannerImage] = useState(getBannerImage());

  const handleBannerImageChange = (imgFile) => {
    setImageFile(imgFile);
    setSelectedImage(imgFile);
    setEditBanner(true);
    setUploadModal(false);
  };

  const discardChanges = () => {
    setSelectedImage(null);
    setEditBanner(false);
  };

  const saveShade = () => {
    handleAsync(updateBannerImage({ bannerShade: !bannerShade, bannerImage, profileId }).unwrap());
  };

  const saveSelectedImage = async (selectedImage) => {
    const file = selectedImage;
    const [flag, response] = await handleAsync(uploadBlob(file, 'profiles-banners'));
    if (!flag) {
      const isErrorString = typeof response === 'string';
      const error = isErrorString ? response : 'Error uploading files';
      return showErrorToast(error);
    }
    const [status] = await handleAsync(
      updateBannerImage({ bannerImage: response, bannerShade, profileId }).unwrap()
    );
    if (status && image) {
      handleAsync(deleteFromS3(image));
    }

    const uploadedImageUrl = getS3Url(response);
    setBannerImage(uploadedImageUrl);
    setUploadModal(false);
    setEditBanner(false);
  };

  const handleRecommendedImageClick = async (imageUrl) => {
    const [status] = await handleAsync(
      updateBannerImage({ bannerImage: imageUrl, bannerShade, profileId }).unwrap()
    );
    if (!status) {
      showErrorToast('Something went wrong');
      return;
    } else if (image) handleAsync(deleteFromS3(image));
    setBannerImage(imageUrl);
    setImageFile(null);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (uploadModal && uploadModalRef.current && !uploadModalRef.current.contains(e.target)) {
        setUploadModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [uploadModal]);

  useEffect(() => {
    const img = getBannerImage();
    setBannerImage(img);
  }, [templateType]);

  const companyTheme = useSelector(getCompanyThemeSelector);

  const handleCloseMpublisherBackgrounds = () => {
    setMpublisherBackgrounds(false);
    setTempImage(null);
  };

  const ImageSelectionModal = ({ images, handleImageClick, activeImage, extraClass }) => {
    return (
      <div className={`${styles.imageSelectionModal} ${styles[extraClass]}`}>
        {images.map((imageUrl) => (
          <div
            key={imageUrl}
            className={
              activeImage === imageUrl ? `${styles.active} ${styles.container}` : styles.container
            }
          >
            <img
              onClick={() => handleImageClick(imageUrl)}
              src={imageUrl}
              alt="banner image"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <ImageCropModal
        onClose={() => {
          setOpenImageCroppedModal(false);
          discardChanges();
        }}
        isOpen={openImageCroppedModal}
        onChange={(file) => {
          handleBannerImageChange(file);
        }}
        file={imageFile}
        onSubmit={(file) => {
          setOpenImageCroppedModal(false);
          handleBannerImageChange(file);
          handleAsync(saveSelectedImage(file));
        }}
      ></ImageCropModal>
      <div
        className={
          bannerShade
            ? `${styles.bannerEffect} ${styles.bannerProfile} ${styles[extraClass]}`
            : `${styles.bannerProfile} ${styles[extraClass]}`
        }
        style={{
          backgroundImage: `url(${getBannerImage()})`,
          transition: 'background-image 0.5s ease-in-out',
          height: rest?.bannerheight,
          borderRadius: rest?.radius
        }}
      >
        {isLoading || (isUploading && <Loader section />)}
        {!rest.isCurrentUserProfile ? null : !editBanner ? (
          <div className={`${styles.editIcons} ${styles.icons}`}>
            {!isSharedMode() && (
              <div>
                {companyTheme === 'default' && (
                  <div className={styles.upload}>
                    <label
                      onClick={() => {
                        setUploadModal(true);
                        setCurrentStep(currentStep + 1);
                      }}
                    >
                      <Upload />
                    </label>
                    <HiddenFileInput
                      id="uploadFile"
                      accept="image/*"
                      onChange={(e) => {
                        const f = e.target.files[0];
                        handleBannerImageChange(f);
                        setImageFile(f);
                        setOpenImageCroppedModal(true);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.editBannerIcons}></div>
        )}
        <div className={styles.profilepic}>
          <ProfilePic
            extraClass="profileMain"
            width={width}
            height={height}
            image={isSharedMode() ? getS3Url(sharedData?.data?.sharedProfile?.picture) : profile}
          />
        </div>
      </div>
      {uploadModal && (
        <div ref={uploadModalRef} className={styles.bannerUpload}>
          <div className={styles.labelHolder}>
            <label className={styles.labelHolder} htmlFor="uploadFile">
              Upload Image
              <Upload />
            </label>
            <Button onClick={() => saveShade()}>
              {bannerShade ? 'Remove Shade' : 'Add Shade'}
            </Button>
          </div>
          <div>
            <HiddenFileInput
              id="uploadFile"
              accept="image/*"
              onChange={(e) => {
                const f = e.target.files[0];
                handleBannerImageChange(f);
                setImageFile(f);
                setOpenImageCroppedModal(true);
              }}
            />
          </div>
          <ImageSelectionModal
            images={RECOMMENDED_IMAGES}
            handleImageClick={handleRecommendedImageClick}
            activeImage={bannerImage}
          />
          <button
            onClick={() => {
              setMpublisherBackgrounds(true);
              setUploadModal(false);
            }}
            className={styles.mpublisherBtn}
          >
            or choose from <span>MPublisher</span>
          </button>
        </div>
      )}
      {mpublisherBackgrounds && (
        <CreateModal
          extraClass="mpublisherModal"
          clickEvent={() => {
            if (tempImage) {
              handleRecommendedImageClick(tempImage);
            }
            handleCloseMpublisherBackgrounds();
          }}
          handleClose={handleCloseMpublisherBackgrounds}
        >
          <h1>
            <MPublisher /> MPublisher
          </h1>
          <ImageSelectionModal
            images={MPUBLISHER_BACKGROUNDS}
            handleImageClick={setTempImage}
            activeImage={tempImage}
            extraClass="mpublisherBackgrounds"
          />
          <p className={styles.seeMore}>
            Want to see more?{' '}
            <a href="https://publisher.masterwizr.com" target="_blank">
              Explore MPublisher
            </a>
          </p>
        </CreateModal>
      )}
    </div>
  );
}

BannerProfile.propTypes = {
  image: PropTypes.string,
  profile: PropTypes.object,
  bannerShade: PropTypes.bool,
  profileId: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  extraClass: PropTypes.string,
  rest: PropTypes.object,
  isCurrentUserProfile: PropTypes.bool,
};
