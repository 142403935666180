import styles from './ProfilePic.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import profileImg from 'assets/images/avatar.webp';
export default function ProfilePic({
  image,
  width,
  height,
  extraClass,
  clickable,
  profileId,
  onClick
}) {
  return (
    <div className={`${styles.container} ${styles[extraClass]}`}>
      {clickable ? (
        <Link to={`/profile/${profileId}`} replace={true}>
          {' '}
          <div
            className={styles.profileImage}
            style={{ backgroundImage: `url(${image || profileImg}`, width, height }}
          />
        </Link>
      ) : (
        <div
          onClick={() => onClick?.()}
          className={styles.profileImage}
          style={{ backgroundImage: `url(${image || profileImg}`, width, height }}
        />
      )}
    </div>
  );
}
ProfilePic.propTypes = {
  image: PropTypes.string | PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  extraClass: PropTypes.string
};
