export const courseReviewData = [
  {
    id: 1,
    name: 'Ali Barznji',
    date: '10/10/2020',
    rating: 5,
    description: `I have an understanding that, even if the work is not perfect, it's a work in progress. And the reason why I'm on WiZR UP is to develop a skill. I feel that it's a safe space.`,
    img: 'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    studentTitle: 'Student at CoderSchool'
  },
  {
    id: 2,
    name: 'Ali Barznji',
    date: '10/10/2020',
    rating: 3,
    description: `I have an understanding that, even if the work is not perfect, it's a work in progress. And the reason why I'm on WiZR UP is to develop a skill. I feel that it's a safe space.`,
    img: 'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    studentTitle: 'Student at CoderSchool'
  },
  {
    id: 3,
    name: 'Ali Barznji',
    date: '10/10/2020',
    rating: 4,
    description: `I have an understanding that, even if the work is not perfect, it's a work in progress. And the reason why I'm on WiZR UP is to develop a skill. I feel that it's a safe space.`,
    img: 'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    studentTitle: 'Student at CoderSchool'
  },
  {
    id: 4,
    name: 'Ali Barznji',
    date: '10/10/2020',
    rating: 1,
    description: `I have an understanding that, even if the work is not perfect, it's a work in progress. And the reason why I'm on WiZR UP is to develop a skill. I feel that it's a safe space.`,
    img: 'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    studentTitle: 'Student at CoderSchool'
  }
];
