import { THEMES } from 'constants';
import { MEDIA_TYPES } from 'constants/general';
import {
  videoTypes,
  pdfTypes,
  excelTypes,
  zipTypes,
  pptTypes,
  docTypes,
  audioTypes,
  imagesTypes,
  MW_MODULES_MAPPERS
} from 'constants/general';

export const getHiddenUMIcons = (accessedModules = []) => {
  const accessedModulesNames = accessedModules.map((mod) => mod.name);
  return Object.keys(MW_MODULES_MAPPERS)
    .filter((mod) => !accessedModulesNames.includes(mod))
    .map((mod) => MW_MODULES_MAPPERS[mod])
    .join(',');
};
export const mediaTypeMapper = (mediaType) => {
  if (imagesTypes.includes(mediaType)) return MEDIA_TYPES.IMAGE;
  if (videoTypes.includes(mediaType)) return MEDIA_TYPES.VIDEO;
  if (audioTypes.includes(mediaType)) return MEDIA_TYPES.AUDIO;
  if (pdfTypes.includes(mediaType)) return MEDIA_TYPES.PDF;
  if (docTypes.includes(mediaType)) return MEDIA_TYPES.DOC;
  if (excelTypes.includes(mediaType)) return MEDIA_TYPES.EXCEL;
  if (pptTypes.includes(mediaType)) return MEDIA_TYPES.PPT;
  if (zipTypes.includes(mediaType)) return MEDIA_TYPES.ZIP;
  return 'unknown';
};

export const createImage = (fileOrUrl) => {
  fileOrUrl;
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    const srcInput = typeof fileOrUrl === 'string' ? fileOrUrl : URL.createObjectURL(fileOrUrl);
    image.src = srcInput;
  });
};

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      file;
      resolve(file);
    }, 'image/jpeg');
  });
}

export const fallbackCopyTextToClipboard = (text, callback) => {
  const inputLink = document.createElement('input');
  inputLink.value = text;
  document.body.appendChild(inputLink);
  inputLink.select();
  document.execCommand('copy');
  callback();
};

export const mailToEmail = (emails, subject = '', body = '') => {
  const mailTo = `mailto:${emails}?subject=${subject}&body=${body}`;
  window.open(mailTo, '_blank');
};

/**
 *
 * @description this function is used to get the theme object
 * @param {string} themeName - name of the theme
 * theme name must be one of the following:
 * 1. 'ngo'
 * 2. 'startup'
 * 3. 'freelancer'
 * 4. 'community'
 * @param {string} themeSection - name of the section
 * @returns {object} - theme object
 */
export const getTheme = (themeName, themeSection) => {
  const theme = THEMES[themeName];
  if (!theme) return {};
  return theme[themeSection];
};

export const maxWords = (max, text) => {
  const words = text.trim().split(/\s+/);
  return words.length > max;
};

export const trimFileName = (name, max) => {
  if (name.length > max) {
    const extension = name.split('.').pop();
    const baseName = name.split('.')[0].substring(0, max);
    return `${baseName}...${extension}`;
  }
  return name;
};
