import React from 'react';
import styles from './Notification.module.scss';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationMenuSelector, setNotificationMenu } from 'store/individualProfile.slice';
import { useOutSideClick } from 'hooks';
import { Link } from 'react-router-dom';
import { ReactComponent as NotificationIcon } from 'assets/icons/greyBellIcon.svg';
import { getCompanyThemeSelector } from 'store/login.slice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function Notification({ children, unReadCount, setOpenMenu }) {
  const openMenu = useSelector(getNotificationMenuSelector);
  const dispatch = useDispatch();
  const companyTheme = useSelector(getCompanyThemeSelector);
  return (
    <div className={styles['wrapper']}>
      <div
        className={
          location.pathname === '/info-hub' && companyTheme !== 'default'
            ? styles.infoHubNotification
            : undefined
        }
        id="notification_bell"
        onClick={() => {
          dispatch(setNotificationMenu(!openMenu));
          setOpenMenu(false);
        }}
        style={{
          cursor: 'pointer',
          position: 'relative',
          marginTop: '5px'
        }}
      >
        <NotificationIcon />
        {unReadCount > 0 && (
          <div className={styles['notificationCount']}>{`${
            unReadCount > 9 ? '+9' : unReadCount
          }`}</div>
        )}
      </div>

      {openMenu && (
        <>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {children}
          </motion.div>
        </>
      )}
    </div>
  );
}

// eslint-disable-next-line react/display-name
Notification.Menu = React.forwardRef(({ children, onMarkAllReadClick }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useOutSideClick(
    ref,
    () => {
      dispatch(setNotificationMenu(false));
    },
    'notification_bell'
  );
  return (
    <div ref={ref} className={styles['notificationMenu']}>
      {' '}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h1 className={styles['notificationMenu__title']}>{t('Notifications')}</h1>
        <span onClick={onMarkAllReadClick} className={styles['mark-all-read']}>
          {t('Mark all read')}
        </span>
      </div>
      <div className={styles['line']} />
      {children}
    </div>
  );
});

Notification.Item = function Item({ profilePic, title, description, date, link, read, onClick }) {
  const dispatch = useDispatch();

  return (
    <Link
      onClick={() => {
        dispatch(setNotificationMenu(false));
        onClick?.();
      }}
      to={link}
      replace={true}
      className={styles['notificationItem']}
    >
      <img
        src={profilePic ? profilePic : `/src/assets/images/avatar.webp`}
        alt="profile-pic"
        loading="lazy"
      />
      <div
        className={`${styles['notificationItem__content']} ${styles[!read ? 'unread-item' : '']}`}
      >
        <p className={styles['title']}>{title}</p>
        <p className={styles['description']}>{description}</p>
        <p className={styles['time']}>{`${moment(new Date(date), 'YYYYMMDD').fromNow()}`}</p>
      </div>
    </Link>
  );
};

export default Notification;
