export * from './async';
export * from './auth';
export * from './dateHandler';
export * from './dom';
export * from './s3Handler';
import { COUNTRIES_BY_CONTINENTS } from 'constants';
import { compareDates } from './dateHandler';
import { mediaTypeMapper } from './general';
import routes from 'routes';

// ====================== Array ======================
// ====================== Array ======================

export const addObjectToArrayIfNotExist = (arr, obj) => {
  const newArr = arr;
  if (!newArr.some((el) => el.id === obj.id)) newArr.push(obj);
  return newArr;
};

export const replaceOrAddObjectInArray = (arr, obj, key) => {
  const newArr = arr;
  const index = newArr.findIndex((el) => el[key] === obj[key]);
  if (index !== -1) newArr[index] = obj;
  else newArr.push(obj);
  return newArr;
};

export const trimArray = (arr) => {
  const newArr = [...arr];
  newArr.forEach((el, i) => {
    if (typeof el === 'string') newArr[i] = el.trim();
  });
  return newArr;
};
export const arraysOfObjectsEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (!objectsEqual_ByKeys(arr1[i], arr2[i])) return false;
  }
  return true;
};
export const flattenArray = (arr) => {
  if (!arr) return [];
  return arr.reduce((acc, val) => acc.concat(val), []);
};

export const trimThenLowerCase = (strings) => {
  let stringsArr = [...strings];
  stringsArr = stringsArr.filter((str) => !isStringEmpty(str));
  return stringsArr.map((str) => str.trim().toLowerCase());
};

// ====================== Object ======================
// ====================== Object ======================

export const updateObjectEntry = (obj, key, value) => {
  const keys = key.split('.');
  const lastKey = keys.pop();
  let nestedObj = obj;

  keys.forEach((k) => {
    if (!(k in nestedObj)) {
      nestedObj[k] = {};
    }
    nestedObj = nestedObj[k];
  });

  if (typeof value === 'object' && nestedObj[lastKey] === 'object') {
    // if it's object and it's not empty or  null, we use assign , since value can be null
    nestedObj[lastKey] = value ? { ...nestedObj[lastKey], ...value } : value;
  } else nestedObj[lastKey] = value;

  return obj;
};

export const removeUndefinedElements = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] && typeof newObj[key] === 'object') removeUndefinedElements(newObj[key]);
    else if (!newObj[key]) delete newObj[key];
  });

  return newObj;
};

export const trimObject = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] && typeof newObj[key] === 'object') trimObject(newObj[key]);
    else if (typeof newObj[key] === 'string') newObj[key] = newObj[key].trim();
  });

  return newObj;
};

export const isObjectEmpty = (obj) => {
  return !obj || Object.keys(obj).length === 0;
};

export const removeObjectElements = (obj, keys) => {
  const newObj = { ...obj };
  keys.forEach((key) => {
    delete newObj[key];
  });
  return newObj;
};

export const selectObjectElements = (obj, keys) => {
  const newObj = {};
  keys.forEach((key) => {
    newObj[key] = obj[key];
  });
  return newObj;
};

export const addObjectProperty = (obj, key, value) => {
  if (key.includes('.')) {
    const keys = key.split('.');
    const newObj = { ...obj };
    newObj[keys[0]] = { ...newObj[keys[0]] };
    newObj[keys[0]][keys[1]] = value;
    return newObj;
  } else {
    return { ...obj, [key]: value };
  }
};

export const objectsEqual_ByStringify = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
export const objectsEqual_ByKeys = (obj1, obj2) => {
  if (!obj1 || !obj2) return false;
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
  for (const key in obj1) {
    if (obj1[key] && typeof obj1[key] === 'object') {
      if (!objectsEqual_ByKeys(obj1[key], obj2[key])) return false;
    } else if (obj1[key] !== obj2[key]) return false;
  }
  return true;
};

const replaceObjectKey = (obj, key, newKey) => {
  const newObj = { ...obj };
  const targetKey = newObj[key];
  if (!targetKey) return obj;
  delete newObj[key];
  newObj[newKey] = targetKey;
  return newObj;
};

export const handleStartedFilter = (filter) => {
  const newFilter = { ...filter };
  if (newFilter.startedAt) {
    const startedAt = newFilter.startedAt;
    delete newFilter.startedAt;
    newFilter['startedAt[gte]'] = new Date(`${startedAt}`).toISOString(); // convert to number
    newFilter['startedAt[lte]'] = new Date(startedAt, 12, 1).toISOString();
  }

  return newFilter;
};
export const prepareProfilesQuery = (filter) => {
  let profilesFilter = { ...filter };
  profilesFilter = replaceObjectKey(profilesFilter, 'Location', 'contact.country');
  profilesFilter = replaceObjectKey(profilesFilter, 'Continent', 'contact.continent');
  profilesFilter = replaceObjectKey(profilesFilter, 'Position', 'jobPosition');
  profilesFilter = replaceObjectKey(profilesFilter, 'Started', 'startedAt');
  profilesFilter = replaceObjectKey(profilesFilter, 'Experience', 'experience');
  profilesFilter = replaceObjectKey(profilesFilter, 'Team', 'team');
  if (Object.keys(profilesFilter).length === 0) return '';
  profilesFilter = handleStartedFilter(profilesFilter);
  let query = '';
  const entriesLength = Object.entries(profilesFilter).length;
  Object.entries(profilesFilter).forEach(([key, value], upIndex) => {
    if (typeof value !== 'object') {
      if (upIndex !== entriesLength - 1) {
        query += `${key}=${value}&`;
      } else {
        query += `${key}=${value}`;
      }
    } else if (typeof value === 'object') {
      if (key === 'regex') {
        const regexEntriesLength = Object.entries(value).length;
        Object.entries(value).forEach(([key, value], index) => {
          let keyToUse = key;
          if (key.includes(','))
            keyToUse = key
              .split(',')
              .map((el) => el.trim())
              .join(',');
          if (value)
            query += `regex[${keyToUse}]=${value}${index !== regexEntriesLength - 1 ? '&' : ''}`;
        });
        query += ` ${upIndex !== entriesLength - 1 ? '&' : ''}`;
      }
    }
  });
  return query;
};

// ====================== Input utils ======================
// ====================== Input utils ======================
export const isValidEditPagePhoneNumber = (phoneNumber) => {
  const regex = /^\+\d{1,}_\d{7,}$/;
  return phoneNumber && regex.test(phoneNumber);
};
const correctUrls = ['facebook.com', 'x.com', 'linkedin.com'];
export const checkIsValidUrl = (url) => {
  try {
    const realUrl = new URL(url);
    const { hostname } = realUrl;
    const realHostname = hostname.includes('www.') ? hostname.split('www.')[1] : hostname;
    if (!realUrl.protocol.includes('https') || !correctUrls.includes(realHostname)) return false;
    return true;
  } catch (_) {
    return false;
  }
};

export const checkIsValidUrlVideo = (url) => {
  if (isVideoURL(url)) {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  } else {
    return false;
  }
};

export const isValidEmail = (email) => {
  'email', email;
  const regex = /\S+@\S+\.\S+/;
  return { isValid: regex.test(email), message: 'Invalid email' };
};

export const required = (value) => {
  'value', value;
  return { isValid: !!value, message: 'Required' };
};

export const isValidPhoneNumber = (phoneNumber) => {
  const regex = /^\d{7,}$/;
  return { isValid: regex.test(phoneNumber), message: 'Invalid phone number' };
};

export const min = (min) => {
  return (value) => {
    return { isValid: value.length >= min, message: `Minimum ${min} characters` };
  };
};

export const max = (max) => {
  return (value) => {
    return { isValid: value.length <= max, message: `Maximum ${max} characters` };
  };
};
// ====================== String ======================
// ====================== String ======================

export const getDialAndNumber = (phoneNumber) => {
  if (!phoneNumber) return ['', ''];
  const dialCode = phoneNumber.split('_')[0] || '';
  const number = phoneNumber.split('_')[1] || '';
  const completeNumber = `${dialCode}${number}`;
  return [dialCode, number, completeNumber];
};

export const isStringEmpty = (str) => {
  return !str || 0 === str.length;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const addStringSeparator = (str1, str2) => {
  return `${capitalizeFirstLetter(str1)} | ${capitalizeFirstLetter(str2)}`;
};

export const replaceAllEmptySpaces = (str, newChar) => {
  return str.replace(/\s/g, newChar);
};

export const isStringLimited = (str, limit) => {
  const val = str.substring(0, limit);
  return [str.length < limit, val];
};

export const isStringLimitedByWords = (str, limit) => {
  const words = str.split(' ');
  const val = words.slice(0, limit).join(' ');
  return [words.length > limit, val];
};
// ====================== Others ======================
// ====================== Others ======================

export const getContinents = () => {
  const continents = COUNTRIES_BY_CONTINENTS.map((c) => trimThenLowerCase([c.continent])[0]);
  const continentsSet = new Set(continents);
  const continentsArray = [...continentsSet];
  return continentsArray;
};

export const getCountryContinent = (country) => {
  const countryObj = COUNTRIES_BY_CONTINENTS.find(
    // ? is added since country will be null at the beginning
    (c) => c.text.toLowerCase() === country?.toLowerCase()
  );
  if (!countryObj) return '';
  return countryObj.continent.toLowerCase();
};

export const onFieldChangeProfileModules = (name, value, setFieldsChange, fieldsChange) => {
  if (name.includes('.')) {
    const objName = name.split('.')[0];
    const attrName = name.split('.')[1];
    setFieldsChange({
      ...fieldsChange,
      [objName]: {
        ...fieldsChange[objName],
        [attrName]: value
      }
    });
  } else {
    if (name === 'from') {
      if (compareDates(fieldsChange.to, value) !== 1) {
        const from = value;
        let to = new Date(from).setMonth(new Date(from).getMonth() + 1);
        to = new Date(to).toString();
        return setFieldsChange({ ...fieldsChange, from, to });
      }
    }
    setFieldsChange({
      ...fieldsChange,
      [name]: value
    });
  }
};

export const parseObjectProfileModules = (name, value, obj) => {
  if (name.includes('.')) {
    const objName = name.split('.')[0];
    const attrName = name.split('.')[1];
    obj[objName] = {
      ...obj[objName],
      [attrName]: value
    };
  } else {
    if (name === 'from') {
      if (compareDates(obj.to, value) !== 1) {
        const from = value;
        let to = new Date(from).setMonth(new Date(from).getMonth() + 1);
        to = new Date(to).toString();
        obj = { ...obj, from, to };
        return obj;
      }
    }
    obj[name] = value;
  }
};

export const removeHeightSetWidthSvgIcon = (svgString, width = '20px') => {
  if (!svgString || !svgString.includes('height="')) return svgString;
  svgString = svgString.replace(/width=".*?"/, `width="${width}"`);
  return svgString.replace(/height=".*?"/, ``);
};

export const navigateOutsideApp = (url) => {
  window.open(url, '_blank');
};

export const isImageURL = (format) => {
  return format.match(/(jpeg|jpg|gif|png|jfif)$/);
};

export const isVideoURL = (format) => {
  return format.match(/(mp4|webm|ogg)$/);
};

// ====================== Others ======================

export const isSharedMode = () => {
  const allRoutes = routes;
  const routesRegex = allRoutes
    .filter((route) => route.isSharable && route.acceptedRegex)
    .map((route) => route.acceptedRegex);
  const acceptedRegex = [...routesRegex];
  return acceptedRegex.some((regex) => regex.test(window.location.href));
};

export const isGlobalMode = () => {
  const allRoutes = routes;
  const routesRegex = allRoutes
    .filter((route) => route.isGlobal && route.acceptedRegex)
    .map((route) => route.acceptedRegex);
  const acceptedRegex = [...routesRegex];
  return acceptedRegex.some((regex) => regex.test(window.location.href));
};
export const getAppUrl = () => {
  if (import.meta.env.MODE === 'production') return window.location.hostname;
  else return `${window.location.hostname}:${window.location.port}`;
};

export const getSharedPostUrlById = (id) => {
  return `${getAppUrl()}/post/${id}/?shared=true`;
};

export const getUrlFileType = (fileUrl) => {
  const format = fileUrl.split('.').pop();
  return mediaTypeMapper(format);
};

export const getFileUrlName = (fileUrl) => {
  const temp = fileUrl.split('/').pop();
  const fileExtension = temp.split('.').pop();
  const fileName = temp.split('-').slice(0, -1).join('-');
  return `${fileName}.${fileExtension}`;
};

export const downloadByUrl = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
