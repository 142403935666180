import React, { useState } from 'react';
import styles from './CommentCard.module.scss';
import profileImage from 'assets/images/avatar.webp';
import { Loader, ProfilePic } from 'components/ui';
import { CustomDropdown } from 'components/form';
import { ReactComponent as TripleDots } from 'assets/icons/dropdown.svg';
import { useDeleteCommentByIdMutation } from 'api/social-board/commentSlice';
import AddCommentEditor from 'components/molecules/AddCommentEditor/AddCommentEditor';
import { isImageURL } from 'helpers/index.js';
import { getS3Url } from 'helpers/s3Handler';
import { ReactComponent as Close } from 'assets/icons/closeIcon.svg';
import { handleAsync } from 'helpers/index.js';
import { useUploadBlobs } from 'hooks';
import { Modal } from 'components/modals';
import moment from 'moment';
export default function CommentCard({ comment, allowedToModify, userStatus }) {
  const [editMode, setEditMode] = useState(false);
  const [commentContent, setCommentContent] = useState(JSON.parse(comment.content));
  const [deleteComment, { isLoading: isSaving }] = useDeleteCommentByIdMutation();
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const [showMedia, setShowMedia] = useState(false);
  const [isMobile] = useState(window.innerWidth <= 767);
  const { uploadBlobs } = useUploadBlobs();

  const EditingOptions = [
    {
      id: 1,
      text: 'Edit',
      onClick: () => {
        setEditMode(true);
      }
    },
    {
      id: 2,
      text: 'Delete',
      onClick: async () => {
        handleAsync(
          deleteComment({
            commentId: comment.id,
            postId: comment.postId
          })
        );
      }
    }
  ];

  const handleMediaClick = (index) => {
    setSelectedMediaIndex(index);
    setShowMedia(true);
  };
  if (isSaving) return <Loader section={true} />;

  return (
    <div className={styles.container}>
      <div className={styles.profilerow}>
        <div className={styles.profileinfo}>
          <ProfilePic
            image={comment?.profile?.picture ? getS3Url(comment?.profile?.picture) : profileImage}
            width={35}
            height={35}
            extraClass="cardprofile"
          />
          <div className={styles.info}>
            <div className={styles.nameWrapper}>
              <h3 className={styles.name}>
                {`${comment?.profile?.contact?.firstName} ${comment?.profile?.contact?.lastName}`}
              </h3>
              <p>{`${moment(new Date(comment.createdAt), 'YYYYMMDD').fromNow()}`}</p>
            </div>
            <div className={isMobile ? '' : styles.content}>
              {!isMobile &&
                (editMode ? (
                  <AddCommentEditor
                    isUpdating={true}
                    userStatus={userStatus}
                    postId={comment.postId}
                    comment={commentContent}
                    uploadBlobs={uploadBlobs}
                    setComment={setCommentContent}
                    cancel={() => {
                      setEditMode(false);
                    }}
                    commentId={comment.id}
                    mediaLinks={comment.media}
                  />
                ) : (
                  <div>{JSON.parse(comment.content)}</div>
                ))}
            </div>
          </div>
        </div>
        {allowedToModify ? (
          <div className={styles.options}>
            <CustomDropdown
              Icon={<TripleDots />}
              list={EditingOptions}
              extraClass={'editOptionsDropDownComments'}
              clickable={true}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {isMobile &&
        (editMode ? (
          <AddCommentEditor
            isUpdating={true}
            userStatus={userStatus}
            postId={comment.postId}
            comment={commentContent}
            uploadBlobs={uploadBlobs}
            setComment={setCommentContent}
            cancel={() => {
              setEditMode(false);
            }}
            commentId={comment.id}
            mediaLinks={comment.media}
          />
        ) : (
          <div className={`${styles.content} ${styles.comment}`}>{JSON.parse(comment.content)}</div>
        ))}
      {comment.media.length && !editMode ? (
        <div className={styles.files}>
          {comment.media.map((media, index) => {
            const url = getS3Url(media.link);
            const extention = url.split('.').pop();
            const urlIsImage = isImageURL(extention.toLowerCase());
            return (
              <div
                className={styles.mediacontainer}
                key={index}
                onClick={() => handleMediaClick(index)}
              >
                {urlIsImage ? <img src={url} alt="dynamic image" /> : <video controls src={url} />}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {showMedia && selectedMediaIndex !== null && (
        <Modal
          extraClass={showMedia ? 'mediaModal' : 'hiddenModal'}
          onClose={() => setShowMedia(false)}
        >
          {(() => {
            const selectedMedia = comment.media[selectedMediaIndex];
            const url = getS3Url(selectedMedia.link);
            const extension = url.split('.').pop();
            const urlIsImage = isImageURL(extension.toLowerCase());

            if (urlIsImage) {
              return (
                <div className={styles.selectedMedia}>
                  <img src={url} alt="media" />
                  <Close onClick={() => setShowMedia(false)} />
                </div>
              );
            } else {
              return (
                <div className={styles.selectedMedia}>
                  <video controls src={url} />
                  <Close onClick={() => setShowMedia(false)} />
                </div>
              );
            }
          })()}
        </Modal>
      )}
    </div>
  );
}
