import React from 'react';
import defaultProfileImage from 'assets/images/avatar.webp';
import styles from './ProfileSmallCard.module.scss';
function ProfileSmallCard({ picture, contact, onRemoveClicked, bg = '#1e1e1e', ...res }) {
  return (
    <div
      className={styles['simple-profile-card']}
      style={{
        backgroundColor: bg
      }}
    >
      <img src={picture ? picture : defaultProfileImage} alt="profile image" />
      <p>
        {contact?.firstName} {contact?.lastName}
      </p>
      <button name="Close" onClick={() => onRemoveClicked(res.id)}>
        X
      </button>
    </div>
  );
}

export default ProfileSmallCard;
