import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { Link, useParams } from 'react-router-dom';
import { Filter } from '../../../Filter';
import DropdownButton from 'components/molecules/DashboardMenuDropdown/DashboardMenuDropdown';

export default function SearchContainer({
  links,
  filterSelection,
  onChangeData,
  navigateToSearchPage,
  menu,
  tabConfig
}) {
  const { tab } = useParams();

  return (
    <div className={styles.container}>
      <div className={styles.search_input_container}>
        <h1 className={styles.header}>My Dashboard</h1>
        <Filter
          data={menu}
          onChangeData={onChangeData}
          onSearch={navigateToSearchPage}
          currentState={filterSelection}
          camelizedValues={true}
        />
      </div>
      <div className={styles.nav_links}>
        <ul className={styles.nav_links_list}>
          {links?.map((link) => (
            <li key={link.value} className={styles.nav_links_item}>
              <Link
                to={link.path}
                className={`${styles.nav__link} ${link?.path?.split('/')?.pop() === tab ? styles.active_link : ''
                }`}
              >
                {link.value}
              </Link>
            </li>
          ))}
        </ul>
        {tabConfig?.type === 'bare' && <button className={styles.nav_links_button} onClick={tabConfig.onClick}>{tabConfig?.label}</button>}
        {tabConfig?.type === 'dropdown' && <DropdownButton {...tabConfig} />}
      </div>
    </div>
  );
}
