import React from 'react'
import CourseCard from 'components/atoms/CourseCard/CourseCard';
import { coursesData } from 'constants/courses';
import styles from 'components/organisms/UpDashboard/DashboardView.module.scss';

const filterContent = coursesData.map(({ url, by, ...rest }) => rest).slice(0, 4);

export default function index() {
  return (
    <>
      <h2>Quizzes</h2>
      <div className={styles.cardlist}>
        {filterContent?.map((d, i) => {
          return <CourseCard {...d} key={i} actions={true} />;
        })}
      </div>

      <h2>Surveys</h2>
      <div className={styles.cardlist}>
        {filterContent?.map((d, i) => {
          return <CourseCard {...d} key={i} actions={true} />;
        })}
      </div>
    </>
  )
}
