import React from 'react';
import CoursesSection from 'components/organisms/CoursesSection/CoursesSection';

export default function Courses() {
  return (
    <>
      <CoursesSection />
    </>
  )
}
