import React, { useState, useEffect, useRef } from 'react';
import styles from './DashboardMenuDropdown.module.scss';

const DashboardMenuDropdown = ({ label, options, onSelect }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    return (
      <div className={styles.dropdownContainer} ref={dropdownRef}>
        <button className={styles.dropdownButton} onClick={toggleDropdown}>
          {label} <span className={styles.arrow}>&#x25BC;</span>
        </button>
        {dropdownOpen && (
          <div className={styles.dropdownMenu}>
            {
              options.map((option) => (
                <div className={styles.dropdownItem} key={option} onClick={() => onSelect(option)}>
                  {option}
                </div>
              ))
            }
          </div>
        )}
      </div>
    );
  };

  export default DashboardMenuDropdown;
  