import React from 'react';
import styles from './ReviewCard.module.scss';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function ReviewCard({ id, name, date, rating, description, img, studentTitle }) {
  return (
    <div className={styles.reviewCard} id={id}>
      <div className={styles.reviewHeader}>
        <div className={styles.profile}>
          <img
            src={img ? img : `assets/images/avatar.webp`}
            alt={`${name}'s profile`}
            className={styles.profilePic}
          />
          <div className={styles.reviewerInfo}>
            <h3 className={styles.reviewerName}>{name}</h3>
            <p className={styles.reviewerRole}>{studentTitle}</p>
          </div>
        </div>
        <div className={styles.rating}>
          <span className={styles.ratingValue}>{rating}</span>
          <div className={styles.stars}>
            {[...Array(5)].map((_, i) => (
              <span
                key={i}
                className={i < Math.floor(rating) ? styles.starFilled : styles.starEmpty}
              >
                ★
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.reviewBody}>
        <p className={styles.reviewText}>
          <blockquote>{description}</blockquote>
        </p>
      </div>
      <p className={styles.reviewDate}>{moment(date).format('MMMM D, YYYY')}</p>
    </div>
  );
}

ReviewCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  rating: PropTypes.number,
  description: PropTypes.string,
  img: PropTypes.string,
  studentTitle: PropTypes.string
};
