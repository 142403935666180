import React from 'react';
import { useSaveReviewMutation } from 'api/individual-profile';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import { motion } from 'framer-motion';
import { ReviewForm, Reviews } from './sections';
import { Loader } from 'components/ui';

function UserReviewsTab({ isCurrentUserProfile, profileId, currentProfileId }) {
  const userStatus = useSelector(getUserStatusSelector);
  const [saveReview, { isLoading: isSaving }] = useSaveReviewMutation();
  const [renderForm, setRenderForm] = React.useState(true);

  if (isSaving) return <Loader section={true} />;
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Reviews
        profileId={profileId}
        currentProfileId={currentProfileId}
        isCurrentUserProfile={isCurrentUserProfile}
        setRenderForm={setRenderForm}
      />
      {!isCurrentUserProfile && renderForm && (
        <ReviewForm profileId={profileId} userStatus={userStatus} saveReview={saveReview} />
      )}{' '}
    </motion.div>
  );
}

export default UserReviewsTab;
