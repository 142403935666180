import React from 'react';
import { Courses, Forms, Resources, Participants, Reviews } from './components/PagesView';

export const tabNames = {
  courses: 'Courses',
  forms: 'Forms',
  resources: 'Resources',
  participants: 'Participants',
  reviews: 'Reviews'
}

export const courseOptions = {
  createCategory: 'Create Category',
  createCourse: 'Create Course'
}

export const formOptions = {
  createQuiz: 'Create Quiz',
  createSurvey: 'Create Survey'
}

export const resourceOptions = {
  upload: 'Upload Resources'
}

export const participantOptions = {
  selectCourse: 'Select Course'
}

export const reviewOptions = {
  salesTraining: 'Sales & Training'
}

const links = [
  {
    value: tabNames.courses,
    path: '/dashboard/courses',
    page: <Courses />,
    button: {
      type: 'dropdown',
      label: 'Create new',
      options: [courseOptions.createCategory, courseOptions.createCourse]
    }
  },
  {
    value: tabNames.forms,
    path: '/dashboard/forms',
    page: <Forms />,
    button: {
      type: 'dropdown',
      label: 'Create new',
      options: [formOptions.createQuiz, formOptions.createSurvey]
    }
  },
  {
    value: tabNames.resources,
    path: '/dashboard/resources',
    page: <Resources />,
    button: {
      type: 'bare',
      label: 'Upload Resources'      
    }  
  },
  {
    value: tabNames.participants,
    path: '/dashboard/Participants',
    page: <Participants />,
    button: {
      type: 'dropdown',
      label: 'Select Course',
      options: [participantOptions.selectCourse]
    }
  },
  {
    value: tabNames.reviews,
    path: '/dashboard/reviews',
    page: <Reviews />,
    button: {
      type: 'dropdown',
      label: 'Sales & Training',
      options: [reviewOptions.salesTraining]
    }
  }
];

export default links;
