import React, { Suspense } from 'react';
import styles from './Reviews.module.scss';
import {
  useDeleteReviewMutation,
  useGetReviewsByProfileIdQuery,
  useUpdateReviewMutation
} from 'api/individual-profile';
import { handleAsync } from 'helpers';
import { removeObjectElements } from 'helpers';
import { Loader } from 'components/ui';
import { useGetUrlQuery, useHandleRtkPagination } from 'hooks';
import { Button } from 'components/ui';
import RatingStars from 'components/molecules/RatingStars/RatingStars';
import Review from '../Review/Review';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from 'utils/toast';
export default function Reviews({ profileId, setRenderForm, currentProfileId }) {
  const [deleteReview, { isLoading: isDeleteLoading }] = useDeleteReviewMutation();
  const [updateReview, { isLoading: isUpdateLoading }] = useUpdateReviewMutation();
  const activeReviewId = useGetUrlQuery('entityId');
  const { t } = useTranslation();

  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getReviewsByProfileId',
    rtkQuery: useGetReviewsByProfileIdQuery,
    rtkQueryArgs: { profileId, activeReviewId }
  });
  const { data, isSuccess, isFetching, isLoading, isError, error } = result;

  const deleteReviewHandler = async (reviewId) => {
    setRenderForm(true);
    return (await handleAsync(deleteReview({ reviewId, profileId }).unwrap()))[0];
  };
  const updateReviewHandler = async (reviewId, review) => {
    const reviewDto = removeObjectElements(review, ['id']);
    return (
      await handleAsync(
        updateReview({
          reviewId,
          review: reviewDto
        }).unwrap()
      )
    )[0];
  };

  const getMeanRatings = (ratings) => {
    const sum = ratings.reduce((a, b) => a + b, 0);
    return (sum / ratings.length).toFixed(1);
  };
  if (isSuccess) {
    return (
      <Suspense fallback={<Loader section={true} />}>
        <div className={styles.reviews}>
          <div className={styles.number}>
            <RatingStars
              size={3}
              value={Math.round(getMeanRatings(data.result.map((review) => review.rating)))}
            />
            <p>
              {!data.result.length > 0
                ? 0
                : getMeanRatings(data.result.map((review) => review.rating))}{' '}
              {`(${data.result.length} ${t('reviewed')})`}
            </p>
          </div>
          {data.result.map((item) => {
            if (item.reviewerProfile.id == currentProfileId) setRenderForm(false);
            return (
              <Review
                originReview={item}
                key={item.id}
                onDelete={() => {
                  deleteReviewHandler(item.id);
                }}
                onUpdate={(review) => {
                  return updateReviewHandler(item.id, review);
                }}
              />
            );
          })}
          {isFetching && <Loader />}
          {data?.page?.hasNextPage && (
            <Button
              color="#bfbfbf"
              onClick={() => setPage((pre) => pre + 1)}
              disabled={isFetching || !data?.page?.hasNextPage}
            >
              {t('Load More')}
            </Button>
          )}
        </div>
      </Suspense>
    );
  }
  if (isError) return showErrorToast(error?.data?.message);
  if (isUpdateLoading) return <Loader section={true} />;
  if (isDeleteLoading) return <Loader section={true} />;
}
