import DashboardView from 'components/organisms/UpDashboard/DashboardView';
import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';

function UpDashboard() {
  document.body.style.overflow = '';

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard/courses" />} />
      <Route path="/:tab" element={<DashboardView />} />
    </Routes>
  );
}

export default UpDashboard;
