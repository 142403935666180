import React, { useRef, useState, memo } from 'react';
import { FaFolderOpen } from 'react-icons/fa';
import { FiLink } from 'react-icons/fi';
import styles from './UploadModal.module.scss';
import MultiFileUploader from '../MultiFileUploader/MultiFileUploader';

const UploadModal = ({ isOpen, onClose }) => {
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleFileSelect = (e) => {
        setFiles((prevFiles) => [...prevFiles, ...Array.from(e.target.files)]);
    };

    const handleClick = () => fileInputRef.current.click();

    const handleLinkPaste = (e) => {
        const link = e.target.value;
        if (link) {
            // Implement link-based file handling here.
        }
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalContainer}>
            <div className={styles.uploadModal}>
                <div className={styles.headerTop} />
                <MultiFileUploader setFiles={setFiles} files={files} />
                <hr />
                <div className={styles.uploadInput}>
                    <label>Local Drive</label>
                    <div className={styles.fileSelect} onClick={handleClick}>
                        <FaFolderOpen className={styles.folderIcon} />
                        <span>Pick from local drive</span>
                    </div>
                    <input
                        type="file"
                        multiple
                        ref={fileInputRef}
                        className={styles.hidden}
                        onChange={handleFileSelect}
                    />
                </div>
                <div className={styles.uploadInput}>
                    <label>Link</label>
                    <div className={styles.spaceBetween}>
                        <input placeholder="Paste link here..." onBlur={handleLinkPaste} />
                        <FiLink />
                    </div>
                </div>
                <div className={styles.modalActions}>
                    <button className={styles.cancelBtn} onClick={onClose}>Cancel</button>
                    <button className={styles.doneBtn}>Done</button>
                </div>
            </div>
        </div>
    );
};

export default memo(UploadModal);
