import React, { useEffect, useLayoutEffect, useState } from 'react';
import styles from './DashboardView.module.scss';
import SearchContainer from './components/SearchContainer';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import links, { courseOptions, formOptions, participantOptions, reviewOptions, tabNames } from './constants';
import { useSearchHook } from './useSearchHook';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import UploadModal from 'components/molecules/UploadModal/UploadModal';

function DashboardView() {
  const [isResourcesModalOpen, setIsResourcesModalOpen] = useState(false);
  const { tab } = useParams();
  const [searchParams] = useSearchParams();
  const { filterSelection, onChangeData, setFilterSelection, getMenu } = useSearchHook();
  const menu = getMenu();
  const navigate = useNavigate();
  const validTab = links.find((link) => link.path === `/dashboard/${tab}`);

  const openResourcesModal = () => setIsResourcesModalOpen(true);
  const closeResourcesModal = () => setIsResourcesModalOpen(false);

  useLayoutEffect(() => {
    if (!tab || !validTab) {
      navigate('/dashboard/courses');
      return;
    }
  }, [tab]);

  useEffect(() => {
    const newFilterSection = {};
    for (let entry of searchParams.entries()) {
      const key = entry[0];
      const value = entry[1].split(',');
      if (menu?.find((item) => item?.dbTitle === key) || key === 'name') {
        newFilterSection[key] = value;
      }
    }
    setFilterSelection(newFilterSection);
  }, [window.location.search]);

  const onSubmit = (possibledata) => {
    const dataToUse = possibledata || filterSelection;
    const updatedSearchParams = new URLSearchParams();
    Object.keys(dataToUse).forEach((key) => {
      if (dataToUse[key]?.length) {
        if (key === 'name' && dataToUse[key][0] === '') {
          return;
        }
        updatedSearchParams.set(key, dataToUse[key].join(',').toLowerCase());
      }
    });
    const sortedSearchParams = new URLSearchParams(
      [...updatedSearchParams.entries()].sort(([a], [b]) => {
        if (a === 'p') return 1;
        if (b === 'p') return -1;
        return 0;
      })
    );
    const newUrl = '/dashboard/courses?' + sortedSearchParams.toString().replace(/%2C/g, ',');
    navigate(newUrl); // Optional: Use if you want to navigate to a specific URL
  };

  const tabActions = {
    [tabNames.courses]: {
      onSelect: (option) => {
        if (option === courseOptions.createCategory) {
          console.log('Create Course Category');
        } else if (option === courseOptions.createCourse) {
          console.log('Create Course Course');
        }
      }
    },
    [tabNames.forms]: {
      onSelect: (option) => {
        if (option === formOptions.createQuiz) {
          console.log('Create Quiz Logic');
        } else if (option === formOptions.createSurvey) {
          console.log('Create Survey Logic');
        }
      }
    },
    [tabNames.resources]: {
      onClick: () => openResourcesModal()
    },
    [tabNames.participants]: {
      onSelect: (option) => {
        if (option === participantOptions.selectCourse) {
          console.log('Select Course Logic');
        }
      }
    },
    [tabNames.reviews]: {
      onSelect: (option) => {
        if (option === reviewOptions.salesTraining) {
          console.log('Add Sales & Training Logic');
        }
      }
    }
  };

  return (
    <ResponsiveWrapper>
      <div className={styles['main-wrapper']}>
        <div className={styles.search_container}>
          <SearchContainer
            links={links}
            filterSelection={filterSelection}
            onChangeData={onChangeData}
            navigateToSearchPage={onSubmit}
            menu={menu}
            tabConfig={{
              ...validTab.button,
              onClick: tabActions[validTab.value]?.onClick,
              onSelect: tabActions[validTab.value]?.onSelect
            }}
          />
        </div>
        <main className={styles.view_container}>{validTab?.page || links[0].page}</main>
      </div>
      <UploadModal isOpen={isResourcesModalOpen} onClose={closeResourcesModal} />
    </ResponsiveWrapper>
  );
}

export default DashboardView;
