import React, { memo, useState } from 'react';
import ReactDOM from 'react-dom';
import { AiOutlineEye, AiOutlineClose } from 'react-icons/ai'; // Preview icon
import { MdDelete } from 'react-icons/md'; // Delete icon
import styles from './UploaderPreview.module.scss';
import { trimFileName } from 'helpers/general';

const UploaderPreview = memo(({ file, onDelete }) => {
    const [showPreview, setShowPreview] = useState(false);

    const handlePreview = () => {
        if (file.type.startsWith('image') || file.type.startsWith('video')) {
            setShowPreview(true);
        } else {
            alert("File preview not supported");
        }
    };

    const renderPreview = () => {
        if (file.type.startsWith('image')) {
            return <img src={URL.createObjectURL(file)} alt={file.name} className={styles.fileThumbnail} />;
        } else if (file.type.startsWith('video')) {
            return <video src={URL.createObjectURL(file)} controls className={styles.fileThumbnail} />;
        } else {
            return <div className={styles.fileThumbnail}>File preview not supported</div>;
        }
    };

    const renderModal = () => {
        return (
            <div className={"upload-preview-modal"}>
                <button className={"upload-preview-closeBtn"} onClick={() => setShowPreview(false)}>
                    <AiOutlineClose size={16} />
                </button>
                {file.type.startsWith('image') ? (
                    <img src={URL.createObjectURL(file)} alt={file.name} className={"upload-preview-image"} />
                ) : (
                    <video src={URL.createObjectURL(file)} controls className={"upload-preview-video"}></video>
                )}
            </div>
        );
    };

    return (
        <div className={styles.fileItem}>
            {renderPreview()}
            <div className={styles.filename}>{trimFileName(file.name, 9)}</div>
            <div className={styles.iconContainer}>
                <button className={styles.previewBtn} onClick={handlePreview}>
                    <AiOutlineEye size={20} />
                </button>
                <button className={styles.deleteBtn} onClick={onDelete}>
                    <MdDelete size={20} />
                </button>
            </div>

            {showPreview && ReactDOM.createPortal(renderModal(), document.body)}
        </div>
    );
});

export default UploaderPreview;
