import React, { useState } from 'react';
import styles from './ReviewForm.module.scss';
import { handleAsync } from 'helpers';
import Form from 'components/molecules/Form/Form';
import { showErrorToast } from 'utils/toast';
import RatingStarsHoverable from 'components/molecules/RatingStarsHoverable/RatingStarsHoverable';
export default function ReviewForm({ saveReview, userStatus, profileId ,rende}) {
  const [rating, setRating] = useState(0);
  const [textArea, setTextAreaValue] = useState('');
  const handleSubmitReview = async (e) => {
    if (!textArea) return;
    if (rating === 0) return showErrorToast('Please choose a rating');
    const [status, result] = await handleAsync(
      saveReview({
        review: {
          reviewerProfile: userStatus.profileStatus.id,
          title: '',
          companyId: userStatus.company.id,
          profileId,
          rating,
          description: textArea
        }
      }).unwrap()
    );

    // TODO: discuss whether the error object will always be the same, if this is the case, add the error
    // notification is "handleAsync"
    if (!status) return;
    setRating(0);
    setTextAreaValue('');
  };
  return (
    <div className={styles.reviewform}>
      <h3>Write a review</h3>
      <RatingStarsHoverable size={3} value={rating} setValue={setRating} />
      <Form handleSubmit={handleSubmitReview} button="Submit a review">
        <textarea
          maxLength={1000}
          value={textArea}
          onChange={(e) => setTextAreaValue(e.target.value)}
          rows={10}
          cols={10}
          placeholder="Share your experience"
        />
      </Form>
    </div>
  );
}
