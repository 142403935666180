import React from 'react'
import CourseCard from 'components/atoms/CourseCard/CourseCard';
import { coursesData } from 'constants/courses';
import styles from 'components/organisms/UpDashboard/DashboardView.module.scss';

const filterContent = coursesData.map(({ url, by, date, ...rest }) => rest);

export default function index() {
  return (
    <>
      <h2>Resources</h2>
      <div className={styles.cardlist}>
        {filterContent?.map((d, i) => {
          return <CourseCard {...d} key={i} actions={true} />;
        })}
      </div>
    </>
  )
}
