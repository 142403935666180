import React, { memo } from 'react';


const SliderArrow = memo(({ Icon, className, style, onClick, position, iconStyles, positionValue=-15 }) => {
    return (
        <div
            className={className}
            style={{ ...style, [position]: positionValue }}
            onClick={onClick}>
            <Icon className={iconStyles} />
        </div>
    )
})

export default SliderArrow
