import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useSearchHook = () => {
  const navigate = useNavigate();
  const [filterSelection, setFilterSelection] = useState({});

  const onChangeData = (changes) => {
    const key = changes.category;
    const value = changes.value?.toLowerCase();
    if (changes.category === 'searchText') {
      setFilterSelection({ ...filterSelection, name: [changes.value] });
    } else if (changes.category === 'assest_type') {
      setFilterSelection({ name: [filterSelection.name], assest_type: [changes.value] });
    } else if (!filterSelection[key]) {
      const newData = {};
      newData[key] = [value];
      setFilterSelection({ ...filterSelection, ...newData });
    } else {
      if (changes.selected) {
        const newArr = [...filterSelection[key], value];
        setFilterSelection({ ...filterSelection, [key]: newArr });
      } else {
        const newArr = filterSelection[key].filter((item) => item !== value);
        newArr.length > 0
          ? setFilterSelection({ ...filterSelection, [key]: newArr })
          : setFilterSelection((prev) => {
              const newState = { ...prev };
              delete newState[key];
              return newState;
            });
      }
    }
  };

  const getMenu = () => {
    // in case we need smth dynamic we can use a generating fn
    return [
      {
        title: 'Levels',
        dbTitle: 'levels',
        filters: [
          {
            name: 'Intern'
          },
          {
            name: 'Lower'
          },
          {
            name: 'Advanced'
          }
        ]
      },
      {
        title: 'Course Field',
        dbTitle: 'course_field',
        filters: [
          {
            name: 'Marketing'
          },
          {
            name: 'Sales'
          },
          {
            name: 'Design'
          },
          {
            name: 'Management'
          },
          {
            name: 'Engineering'
          }
        ]
      },
      {
        title: 'Instructors',
        dbTitle: 'instructors',
        filters: [
          {
            name: 'doesnt'
          },
          {
            name: 'make'
          },
          {
            name: 'sense'
          },
          {
            name: 'at'
          },
          {
            name: 'all'
          }
        ]
      }
    ];
  };
  return {
    filterSelection,
    setFilterSelection,
    onChangeData,
    getMenu
  };
};
