import React from 'react';
import styles from './CustomRadioButton.module.scss';
import PropTypes from 'prop-types';

export default function CustomRadioButton({ checked, onChange }) {
  return (
    <div className={`${styles.radioButton} ${checked ? styles.checked : ''}`} onClick={onChange}>
      <div className={styles.innerCircle}></div>
    </div>
  );
}

CustomRadioButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func
};
