/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Checkbox from 'react-custom-checkbox';
import { ReactComponent as FilterIcon } from '../assets/filter-icon.svg';
import { useTranslation } from 'react-i18next';

const assets = [
  {
    value: null,
    label: 'All Assets'
  },
  {
    value: 'images',
    label: 'Images'
  },
  {
    value: 'templates',
    label: 'Templates'
  },
  {
    value: 'videos',
    label: 'Videos'
  }
];

function Container({
  data,
  extraClass,
  onChangeData,
  onSearch,
  camelizedValues,
  currentState,
  filterOn,
  withAssetBtn = false
}) {
  const [dropdown, setDropdown] = useState(filterOn);
  const [selectedColors, setSelectedColors] = useState(currentState.color || []);
  const { t } = useTranslation();
  const [assetSelector, setAssetSelector] = useState(false);

  const currentAssetType = Array.isArray(currentState.assest_type)
    ? currentState.assest_type[0]
    : null;
  const currentAssetTypeLabel = assets.find((asset) => asset.value === currentAssetType)?.label;

  useEffect(() => {
    setSelectedColors(currentState.color || []);
  }, [currentState]);

  const handleChange = (isColor, itemTitle, value, checked) => {
    let obj = {};
    const categoryTitle = itemTitle;
    if (!isColor) {
      obj = {
        category: categoryTitle,
        value,
        selected: checked
      };
      // setContentState(prev => ({ ...prev, [obj.category]: obj.value }))
    } else {
      if (selectedColors.includes(value)) {
        setSelectedColors(selectedColors?.filter((color) => color !== value));
        obj = {
          category: categoryTitle,
          value,
          selected: false
        };
      } else {
        setSelectedColors([...selectedColors, value]);
        obj = {
          category: categoryTitle,
          value,
          selected: true
        };
      }
    }
    onChangeData(obj);
  };

  const handleChangeAsset = (value) => {
    const newCurrentState = {
      ...currentState,
      assest_type: [value]
    };
    if (value === 'All Assets') {
      delete newCurrentState.assest_type;
    }
    onChangeData({
      category: 'assest_type',
      value
    });
    setAssetSelector(false);
    onSearch(newCurrentState);
  };

  const chckBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: '0.6rem',
    backgroundColor: '#409172',
    alignSelf: 'stretch',
    color: 'white'
  };

  return (
    <div className={`${styles.container} ${styles[extraClass]}`}>
      <div
        className={styles.searchbar}
        style={
          {
            // width: data?.length > 0 ? `${data.length * 130}px` : 'auto'
          }
        }
      >
        {withAssetBtn && (
          <div
            className={`${styles.search_asset_btn_container} ${
              assetSelector ? styles.active_menu : ''
            }`}
          >
            <button
              className={styles.search_asset_btn}
              onClick={() => setAssetSelector((prev) => !prev)}
            >
              {currentAssetTypeLabel}
            </button>
            <ul className={styles.assetSelector_list_container}>
              {assets
                ?.filter((el) => el?.label !== currentAssetTypeLabel)
                ?.map((asset) => (
                  <li
                    key={asset?.label}
                    className={styles.assetSelectorItem}
                    onClick={() => {
                      handleChangeAsset(asset?.label);
                    }}
                  >
                    {t(asset?.label)}
                  </li>
                ))}
            </ul>
          </div>
        )}
        <div
          className={`${styles.input_search_container} ${!withAssetBtn ? styles.onlySearch : ''}`}
        >
          <input
            type="text"
            placeholder={t('search')}
            value={currentState.name || ''}
            onChange={(e) =>
              handleChange(false, 'searchText', e.target.value, e.target.value?.length)
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
          />
        </div>
        <div
          className={`${styles.filter_more_btn_container} ${dropdown ? styles.active_filter : ''}`}
        >
          <button className={styles.filter_more_btn} onClick={() => setDropdown(!dropdown)}>
            <FilterIcon />
          </button>
        </div>
        <div
          className={
            dropdown
              ? `${styles.dropdownContainer} ${styles.activeMenu}`
              : `${styles.dropdownContainer}`
          }
        >
          <div className={styles.filterContainer}>
            {data.map((element) => (
              <div key={element.title} className={styles.filterCell}>
                <p className={styles.filterHeader}>{t(element.title)}</p>
                <div className={styles.cellOptions}>
                  {element.filters?.map((filter) => (
                    <Fragment key={filter.name}>
                      {filter.name !== '' && (
                        <Checkbox
                          checked={currentState[element.dbTitle]?.includes(
                            filter.name?.toLowerCase()
                          )}
                          disabled={false}
                          icon={<div style={chckBoxStyle}>✓</div>}
                          onChange={(value) => {
                            handleChange(false, element.dbTitle, filter.name, value);
                          }}
                          borderColor={
                            currentState[element.dbTitle]?.includes(filter.name?.toLowerCase())
                              ? 'var(--main-color)'
                              : '#e0e0e0'
                          }
                          borderWidth={1}
                          borderRadius={100}
                          style={{ overflow: 'hidden' }}
                          size={16}
                          label={t(filter.name)}
                        />
                      )}
                      <div className={styles.colorContainer}>
                        {element.colors?.map((color) => (
                          <div
                            key={color}
                            className={styles.box}
                            style={{ backgroundColor: color }}
                            onClick={(e) => handleChange(true, element.dbTitle, color)}
                          >
                            {selectedColors?.includes(color) && (
                              <span
                                style={color === 'white' ? { color: 'black' } : { color: 'white' }}
                                className={styles.checkedColor}
                              >
                                ✓
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.applyBtnContainer}>
            <button
              className={styles.applyBtn}
              onClick={() => {
                onSearch();
              }}
            >
              {t('Apply')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Container;
