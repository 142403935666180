export const coursesData = [
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15274_bzggyl.png',
    title: 'Computing',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15273_hxnvbz.png',
    title: 'Engineering',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15277_ov0htd.png',
    title: 'Design',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031953/Templates/Rectangle_15275_vy0ppi.png',
    title: 'Sales Training',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15276_lv1u8s.png',
    title: 'Finance and Accounting',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084641/Templates/Rectangle_15400_1_qqpf3y.png',
    title: 'Computing',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084640/Templates/Rectangle_15399_1_ebten7.png',
    title: 'Engineering',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084642/Templates/Rectangle_15401_1_yrbzvc.png',
    title: 'Design',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023'
  }
];
